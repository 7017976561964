.footer {
  //margin-bottom:$base-line;
  height: 300px;
  padding-top: 3rem;
  font-size: rem-calc(14);

  @include breakpoint(small only) {
    height: auto;
    padding: 2 * $base-line 0 2 * $base-line 0;
  }

  .address {
    //@extend %title-with-orange-line;
    line-height: 1.35;
    white-space: nowrap;

    a {
      display: inline-block;
    }

    .map,
    .map:hover,
    .tel,
    .tel:hover {
      color: $secondary-color;
    }

    @include breakpoint(small only) {
      //padding-left: 0.5rem;
      font-size: rem-calc(13);
      //margin-bottom: 2 * $base-line;
    }
  }

  .nav-item {
    padding-right: 0;
    padding-left: 2 * $base-line;
    padding-bottom: 1rem;

    a {
      color: $primary-color;
    }

    @include breakpoint(small only) {
      padding: 0;
      padding-left: 0.5rem + rem-calc(3);
      display: block;
      margin-bottom: $base-line;
    }
  }
}

.footer__container {
  //display: flex;
  padding-bottom: 2rem;
  //flex-direction: column;
  //
  //@include breakpoint(medium) {
  //  flex-direction: row;
  //}
}

.footer__sub {
  background: $light-gray;
  width: 100%;
  padding: 1rem 0;
  overflow: hidden;
}

.footer__box {
  border-left: 3px solid $primary-color;
  padding-left: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 0.5rem;

  @include breakpoint(medium) {
    margin-bottom: 0;
  }

  .footer__meta {
    display: flex;
    flex-direction: column;
    li a {
      color: $black;
      line-height: 1.8;
      font-size: 1rem;
      text-transform: uppercase;
    }

    li {
      display: block;
    }
  }
}

.footer__submeta {
  display: flex;
  align-items: center;
  align-content: center;

  @include breakpoint(small only) {
    flex-wrap: wrap;

    li {
      margin-bottom: 0.5rem;
    }
  }
  li {
    padding-right: 1rem;
    text-transform: uppercase;
    a {
      color: $secondary-color;
    }
  }
}

.nl__title {
  font-weight: 600;
  line-height: 1.5;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.nl__consent {
  display: flex;
  line-height: normal;
  align-items: center;
  margin-bottom: 1rem;
  &,
  a {
    color: $secondary-color;
  }

  a {
    text-decoration: underline;
  }
}

.button--nl {
  padding: 0.75em 2em;
  border-radius: 5px;
  margin-bottom: 0;
}

.av-checkbox {
  outline: none;
  border: none;
  background-color: $light-gray;
  border-radius: 0;
}

.nl__email {
  outline: none;
  border: none;
  background: $light-gray;
}

.footer__social {
  margin-top: 2rem;
  margin-bottom: 1rem;

  img {
    margin-right: 1rem;
  }
}

//make it sticky to bottom
//see https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/components/site.css

/**
 * 1. Avoid the IE 10-11 `min-height` bug.
 * 2. Set `flex-shrink` to `0` to prevent some browsers from
 *    letting these items shrink to smaller than their content's default
 *    minimum size. See http://bit.ly/1Mn35US for details.
 * 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
 */
body {
  display: flex;
  flex-direction: column;
  height: 100%; /* 1, 3 */
}

.footer {
  flex: none; /* 2 */
}

#siteContainer {
  flex: none; /* 2 */
  //padding: var(--space) var(--space) 0;
  width: 100%;
}
