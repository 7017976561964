// Nav configs
$sticky-header-height: rem-calc(55);
$sticky-header-height-large: 8.5rem;
$sticky-header-height-small: 6.5rem;

$icon-height: 1.5rem;
$icon-width: 2rem;
$icon-margin: 1rem;
$stroke-height: 0.25rem;
$stroke-width: 100%;

$nav-toggle-transition: 0.25s ease-in-out;

// Offset for anchors
a.anchor {
  display: block;
  position: relative;
  visibility: hidden;

  top: -6rem;

  @include breakpoint(large) {
    top: -5.5rem;
  }
}

// Navigation
// ==========

.header {
  padding-top: $sticky-header-height-small;
  @include breakpoint(large) {
    padding-top: $sticky-header-height-large;
  }
  z-index: 1990;

  .page--region & {
    pointer-events: none;
  }

  .nav-is-open & {
    pointer-events: auto;
  }
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  transition: all 0.4s ease;
  height: 8rem;
  z-index: 10000;
  //-webkit-box-shadow: 0 25px 16px 27px #ffffff;
  //box-shadow: 0 4px 3px -2px transparentize($black, 0.3);
  //.at-the-top & {
  //  box-shadow: 0 46px 38px 50px $white;
  //}
  //box-shadow: 0 25px 0 27px $black;

  @include breakpoint(large) {
    .scrolled & {
      transform: translateY(-40%);
    }
  }

  @include breakpoint(medium down) {
    height: 6rem;
    .at-the-top & {
      box-shadow: 0 25px 35px 31px #ffffff;
    }

    .grid-container {
      margin: 0;
      padding: 0;
    }
  }

  .nav-is-open & {
    background: $primary-color;
    height: 100%;
    overflow-y: scroll;
  }
}

.navigation {
  position: relative;
  display: flex;
  //align-items: center;
  padding-top: 1.8rem;
  padding-bottom: 1.3rem;
  justify-content: space-between;
  height: auto;

  .nav-is-open & {
    background: $white;
    padding-bottom: 0;
    height: 6rem;
  }

  @include breakpoint(large) {
    padding-top: 0;
  }

  .navigation--left {
    display: flex;
    flex-direction: row;
    padding-top: 0;
    transform: translateY(-1.4rem);
    min-width: 1px;

    @include breakpoint(large) {
      padding-top: 1.5rem;
      transform: translateY(0);
    }
    .page--region & {
      pointer-events: auto;
    }
    .nav-is-open & {
      pointer-events: auto;
    }
  }

  .navigation--right {
    display: flex;
    justify-content: space-between;
    margin-right: 0.5rem;
    transform: translateY(5px);

    .page--region & {
      pointer-events: auto;
    }
    .nav-is-open & {
      pointer-events: auto;
    }
  }
}

.navigation__social {
  list-style: none;
  margin: 0;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    path {
      fill: $primary-color !important;
      .page--region & {
        fill: $white !important;
      }
    }
    .icon--stroke {
      stroke: $primary-color !important;
      .page--region & {
        stroke: $white !important;
      }
    }
    .icon--fill {
      fill: $primary-color !important;
      .page--region & {
        fill: $white !important;
      }
    }
  }

  .nav-is-open & {
    svg {
      width: 1.5rem;
      height: 1.5rem;
      path {
        fill: $white !important;
      }
      .icon--stroke {
        stroke: $white !important;
      }
      .icon--fill {
        fill: $white !important;
      }
    }
  }

  &.navigation__social--desktop {
    display: none;
    @include breakpoint(large) {
      display: flex;
    }
  }
  &.navigation__social--mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 1.2rem;

    li {
      padding: 0 0.5rem;
    }
    @include breakpoint(large) {
      display: none;
    }
  }
}

.navigation__meta {
  display: flex;
  flex-direction: row;
  align-items: center;
  order: 2;
  list-style: none;
  margin: 0;
  padding: 0.3rem 0.5rem 0.3rem 0;
  line-height: 2;
  color: $primary-color;
  font-size: rem-calc(18px);
  font-weight: 700;
  transition: transform 0.4s ease-in-out;
  @include breakpoint(large) {
    font-size: rem-calc(14px);
    //font-family: $font-body;
    justify-content: flex-end;
    //max-width: 35rem;
    margin-left: auto;
    padding-right: 0;

    a {
      padding: 0 0.5rem;
    }

    .scrolled & {
      transform: translateY(10%);
    }

    .scrolled--down & {
      transform: translateY(-100%);
    }
  }

  .nav-is-open & {
    color: transparentize($white, 0.4);
    flex-direction: column;
    align-items: flex-start;

    padding-bottom: 1.5rem;
    //border-bottom: 2px solid $white;
  }
}

.navigation__meta--left,
.navigation__meta--middle,
.navigation__meta--right {
  list-style: none;
  margin: 0;
  display: flex;
  @extend %sans-serif-regular;
  .nav-is-open & {
    display: flex;
    flex-direction: column;

    li a &:hover {
      color: $black;
    }
  }

  li {
    @include breakpoint(medium down) {
      padding: 0.15rem 0;
    }
  }
}

.navigation__meta--left {
  .nav-item {
    margin-right: 0.75rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .nav-is-open & {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid $white;
  }
}

.navigation__meta--left,
.navigation__meta--middle {
  @include breakpoint(large) {
    margin-right: 2rem;
  }
}

.nav-item:hover a {
  .nav-is-open & {
    color: $black;
  }
}

.navigation__meta--middle {
  .nav-is-open & {
    display: flex;
    flex-direction: row;

    li a {
      color: $white;
      margin-right: 0.5rem;
    }

    li.active a {
      font-weight: bold;
    }
  }
}

.searchbox {
  color: $black;
}

.nav-item--jobs {
  position: relative;
  span {
    margin-left: 0.15rem;
    font-size: 12px;
    color: $white;
    background: $primary-color;
    border-radius: 50%;
    padding: 0 0.3rem;

    .nav-is-open & {
      background: $white;
      color: $primary-color;
      transform: translateY(-1px);
      display: inline-block;
      padding: 0 0.35rem;
    }
  }
}

.navigation__container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  display: none;
  transition:
    transform 0.4s ease-in-out,
    opacity 200ms ease-in-out;
  transform: translateY(-20px);

  @include breakpoint(large) {
    display: flex;
    align-items: center;
    transform: translateY(0);
    opacity: 1;
    position: relative;
    height: 100%;
    margin-top: 0.5rem;

    .navigation__container--overlay {
      width: 100%;
    }
  }

  .nav-is-open & {
    transform: translateY(10px);
    //height: 80vh;
    opacity: 1;
    display: block;
    padding-bottom: 5rem;
    background: transparent;
  }
  @include breakpoint(medium down) {
    margin-top: 5rem;
    .navigation__container--overlay {
      display: flex;
      flex-direction: column;
      padding: 0 2vw;
      margin: 2rem;
    }
  }
}

.navigation__list {
  position: relative;
  order: 1;
  transition: transform 0.4s ease-in-out;
  padding-top: 2rem;
  margin-left: 0;

  .nav-is-open & {
    padding-bottom: 1rem;
    border-bottom: 2px solid $white;
  }

  @include breakpoint(large) {
    display: flex;
    justify-content: flex-end;

    .scrolled & {
      transform: translateY(20%);
    }

    .scrolled--down & {
      //transform: translateY(-40%);
    }
  }

  @include breakpoint(large) {
    padding-left: 3rem;
  }
}

.navigation__item {
  list-style-type: none;
  margin: 0;

  @include breakpoint(large) {
    margin-bottom: 0.1rem;
    text-align: center;
    padding: 0 1.25rem;
    border-right: 3px solid $primary-color;

    &:last-child {
      padding-right: 1rem;
    }
  }
}

.navigation__link {
  position: static;
  display: block;
  color: $black;
  font-size: rem-calc(27px);
  opacity: 0;
  transition-delay: 500ms;
  //padding: 0.1rem 0 0.1rem;

  .active & {
    color: $primary-color;
  }

  @include breakpoint(large) {
    font-size: rem-calc(18px);
    opacity: 1;
    transition: opacity 300ms ease-in-out;
    color: $black;
    text-transform: uppercase;
    line-height: 1;
    .page--region & {
      color: $white;
    }
    &:hover,
    &.active {
      color: $primary-color;
      .page--region & {
        color: $white;
      }
    }
  }
  .nav-is-open & {
    opacity: 1;
    color: $white;
  }
}

.navigation__address {
  display: none;
  flex-direction: column;
  order: 3;
  margin-top: 1rem;

  h3 {
    color: $white;
  }

  span,
  a {
    //@extend %body-font-small;
    color: transparentize($white, 0.4);
    display: block;
  }

  .nav-is-open & {
    display: flex;
  }
}

.navigation__logo {
  display: block;
  //transform: translateY(10px);

  img {
    transition: all 0.2s ease-in;
    transform-origin: 0 30%;
    max-width: 100%;
    width: 100%;
    height: 100%;

    &.header__logo--scrolled {
      height: 3.5rem;
      transform: translateY(35px);
      width: auto;
    }
  }
  @include breakpoint(medium down) {
    padding-left: 0.3rem;
    transform: translateY(12px);
    img {
      height: 3.5rem;
      width: auto;
    }
  }
}

.header__logo--on-top {
  //min-width: 222px;

  @include breakpoint(large) {
    .scrolled & {
      display: none;
    }
  }
}

.header__logo--scrolled {
  display: none;
  @include breakpoint(large) {
    .scrolled & {
      display: block;
    }
  }
}

// Navigation Toggle
// =================

.nav__trigger {
  display: flex;
  align-items: center;
  position: relative;
  width: 30px;
  height: 25px;
  right: 10px;
  z-index: 200;
  transition: all 200ms ease;
  @include breakpoint(large) {
    display: none;
  }
  .navigation__item & {
    .nav-is-open & {
      transition-delay: 0.2s;
      opacity: 1;
      transform: rotate(0);
    }
  }
  .nav-is-open & {
    opacity: 1;
  }
}

.nav__icon {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 3px;
  background-color: $primary-color;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 30px;
    height: 3px;
    position: absolute;
    background: $primary-color;
    transition-property: margin, transform, opacity;
    transition-duration: 300ms;
  }

  &:before {
    margin-top: -10px;
  }

  &:after {
    margin-top: 10px;
  }
  @include breakpoint(large) {
    opacity: 0;
  }
  .nav-is-open & {
    background: none;

    &:before {
      margin-top: 0;
      transform: rotate(45deg);
    }

    &:after {
      margin-top: 0;
      transform: rotate(-45deg);
    }
  }
}
