// Footer specific
footer {
  #mc_embed_signup {
    float: right;
    text-align: left;

    form {
      text-align: right !important;
      input[name="EMAIL"] {
        width: 227px;
      }

      .response {
        float: right !important;
      }
    }
  }
}

// Blog-/Projects-specific
.detail-data,
.blog {
  #mc_embed_signup {
    background: transparent;

    form {
      text-align: left !important;

      input[name="EMAIL"] {
        width: 100%; // better on smaller windows
        max-width: 100%;
        margin-bottom: 0.3rem;
      }
    }
  }
}

// General
#mc_embed_signup div#mce-responses {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;

  .response {
    float: left;
  }
}

.mce_inline_error {
  margin-top: 0.3rem !important;
}

#mc_embed_signup {
  max-width: 327px;

  p {
    margin: 0;
  }

  .button {
    background-color: $primary-color !important;
    font-size: 12px !important;

    // language specific length
    [lang="fr"] & {
      min-width: $sidebar-button-length-fr;
    }

    [lang="en"] & {
      min-width: $sidebar-button-length-en;
    }

    [lang="de"] & {
      min-width: $sidebar-button-length-de;
    }
  }
}
