@use "sass:math";

.background-light {
  background-color: $zebra-light !important;
}

.background-medium {
  background-color: $mlight-gray !important;
}

.background-white {
  background-color: $white !important;
}

.primary-color {
  color: $primary-color !important;
}

%big-link {
  display: block;
  padding-top: $base-line * 0.5;
}

.outbound-link {
  text-transform: uppercase;
}

.project-carrousel {
  background: $light-gray;
}

.longword {
  /*
  -ms-word-break: break-all;
    word-break: break-all;

    // Non standard for webkit
    word-break: break-word;

  -webkit-hyphens: auto;
    -moz-hyphens: auto;
         hyphens: auto;
         */
}

.date {
  @extend %sans-serif-regular;
  color: $secondary-color;
  font-size: $fontsize-date;
}

.richtext {
  //F6 Bug? HACK
  li:before {
    content: none;
    //display: list-item;
  }

  a {
    //display: inline-block;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Mood Image

$moodboard-height-full: 556px;
$moodboard-height-half: 268px;

.image.mood {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  position: relative;

  width: 100%;

  margin-bottom: 2rem;

  //heights
  &.full {
    height: $moodboard-height-full;
    @include breakpoint(medium only) {
      height: $moodboard-height-full * 0.618;
    }
    @include breakpoint(small only) {
      height: $moodboard-height-full * 0.8;
    }
  }

  &.half {
    height: $moodboard-height-half;
    @include breakpoint(medium only) {
      height: $moodboard-height-half * 0.618;
    }
    @include breakpoint(small only) {
      height: $moodboard-height-half * 0.618 * 0.618;
    }
  }

  .overlay {
    position: absolute;
    z-index: 2;
    @extend %sans-serif-bold;
    text-transform: uppercase;

    top: rem-calc(115);
    font-size: rem-calc(42);
    line-height: rem-calc(50);

    @include breakpoint(medium only) {
      top: 2 * $base-line;
      font-size: 2.4rem;
      line-height: 2.4rem * 1.15;
    }
    @include breakpoint(small only) {
      top: 1 * $base-line;
      font-size: 1.6rem;
      line-height: 1.6rem * 1.15;
    }

    .title {
      color: $white;
    }

    .subtitle {
      color: $white;
    }

    .button-link {
      margin-top: rem-calc(50);
      font-size: rem-calc(22);
      //font-weight: normal;
      line-height: 1;
      display: inline-block;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// Flex Slider Startpage

.flexslider {
  background-color: transparent;
  border: 0;

  & .card {
    display: flex;
    flex-direction: column;

    &.card--square {
      height: 480px;
    }

    //need this tho have to shadow rendered
    //margin-bottom: $base-line;
    margin: 5px 5px $base-line 5px;
    //overflow: hidden;
    height: 490px;

    @include breakpoint(medium) {
      height: 390px;
    }

    @include breakpoint(large) {
      height: 430px;
    }

    & > a {
      flex: 1 auto;
      background-size: cover;
    }

    .card__image-container {
      height: 303px;
      width: 100%;

      @include breakpoint(medium) {
        height: 206px;
      }
    }

    .card__image {
      display: block;
      height: 100%;
      background-size: cover;
    }

    .card-label {
      flex: none;
      height: 180px;
      display: flex;
      flex-direction: column;
      padding: 0.8rem 1rem;

      &.card-label--large {
        justify-content: space-between;
        height: 180px;
        @include breakpoint(large) {
          height: 220px;
        }
      }
    }

    h4 {
      flex: 1 auto;
      font-size: 1.4rem;
      margin-bottom: 0;
      @extend %sans-serif-regular;
      color: $black;
      hyphens: manual;
    }

    date,
    .further {
      padding-top: rem-calc($base-line * 0.25);
      flex: none;
      text-transform: capitalize;
    }

    .further {
      font-size: 1rem;
    }
  }
}

//fix
.flex-direction-nav a {
  height: 50px;
  margin-top: -30px;
}

//hacking away the texts
.flex-control-nav li {
  & a:before {
    content: none;
  }
}

////////////////////////////////////////////////////////////////////////////////
// News Flash

.news-flash {
  li {
    margin-bottom: 0.25 * $base-line;
  }

  h4 {
    font-size: 1rem;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Content Box

.cbox {
  position: relative;

  //height addon if directly after moodboard
  .moodboard + & {
    padding-top: $base-line;
  }

  //last box has extra padding if just before footer only
  &:last-child {
    padding-bottom: 3 * $base-line;

    @include breakpoint(small only) {
      padding-bottom: $base-line;
    }
  }
}

.cbox-row {
  position: relative;
  padding: 3 * $base-line 0;

  @include breakpoint(small only) {
    padding: $base-line 0;
  }

  a {
    &.further {
      @extend %big-link;
    }
  }

  //inline telephone numbers
  a[href^="tel:"] {
    color: $secondary-color;
  }

  //TODO!!!
  //append-top if a blank .cbox follows a .cbox.zebra
  /*
  .zebra &+&:not(.zebra) {
    margin-top: $base-line;
  }
  */

  //external content (means video in our case mostly)
  &.external {
    //prevent line heights
    div {
      line-height: 0;
    }
  }
}

.cbox-title,
.cbox-text {
  //default behaviour
  float: right;
}

.cbox-text {
  //fix but why?? foundation tables???
  li::before {
    display: none;
  }
}

.cbox-image {
  //default behaviour
  float: left;

  img {
    width: 100%;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Zebra

.zebra {
  background-color: $light-gray;

  &:nth-child(odd) {
    background-color: $white;

    //default behaviour
    .cbox-title,
    .cbox-text {
      float: left;
    }

    .cbox-image {
      float: right;
    }
  }

  //an anti zebra triggers swapped floating in the following zebras
  //little bit complicated, could be refactored with above
  //but prefer it like that as it way more verbous
  .anti-zebra-4 ~ & {
    .cbox-title,
    .cbox-text {
      float: left;
    }

    .cbox-image {
      float: right;
    }

    &:nth-child(even) {
      .cbox-title,
      .cbox-text {
        float: right;
      }

      .cbox-image {
        float: left;
      }
    }
  }

  //used to set the right 4 columns to the right viewport border to the opposite
  //zebra color.
  @include breakpoint(medium) {
    &.anti-zebra-4 {
      background-size: 100% 100%;
      &.right-side {
        background-image: linear-gradient(
          90deg,
          transparent 50%,
          $zebra-light 50%
        );
      }
      &.left-side {
        background-image: linear-gradient(
          90deg,
          $zebra-light 50%,
          transparent 50%
        );
      }

      &:nth-child(even) {
        &.right-side {
          background-image: linear-gradient(
            90deg,
            transparent 50%,
            $zebra-dark 50%
          );
        }
        &.left-side {
          background-image: linear-gradient(
            90deg,
            $zebra-dark 50%,
            transparent 50%
          );
        }
      }
      &.right-side {
        //calculate center to 4 column on right means 2 offset
        background-position: (math.div($global-width, 12) * 3) 0;
      }
      &.left-side {
        //calculate center to 4 column on left means 2 negative offset
        background-position: -(math.div($global-width, 12) * 3) 0;
      }
      background-repeat: no-repeat;

      &.white-overrride {
        &.right-side {
          background-image: linear-gradient(90deg, transparent 50%, $white 50%);
        }
        &.left-side {
          background-image: linear-gradient(90deg, $white 50%, transparent 50%);
        }
      }
    }
  } //end @include breakpoint(medium) {
} //end .zebra

////////////////////////////////////////////////////////////////////////////////
// Start Page

.inner-box-starting {
  .cbox,
  .cbox:last-child {
    padding-bottom: 0;

    .cbox-row {
      padding: 0;
      margin: 0;

      .columns {
        padding: 0;
      }
    }
  }

  .zebra {
    background-color: transparent;
  }
}

//correct grid when splitting present
.anti-zebra-4 {
  .inner-box-starting {
    .cbox-title,
    .cbox-image,
    .cbox-text {
      @include grid-column-size(12);
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// Team + Team-Detail

.no-lead {
  border-left: none !important;
  padding-left: 0 !important;
}

.controls {
  @extend %big-link;

  //fill in right margin if not the last
  .next + .previous {
    margin-right: $base-line;
  }
}

//override project browser styles
.shadowed.team {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2) !important;

  &:hover {
    box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.2) !important;
    transform: none;
  }

  .card-label {
    font-size: rem-calc(14);

    .card-email {
      @extend %sans-serif-regular;
      color: $primary-color;
      display: none;

      &.show-for-medium-down {
        @include breakpoint(medium down) {
          display: block;
        }
      }

      &.show-for-large {
        @include breakpoint(large) {
          display: block;
        }
      }
    }

    .card-email--small {
      font-size: 0.8rem;
    }
  }
}

.column--no-padding {
}

.team-detail {
  min-height: 1px;
  .controls {
    .back {
      float: left;
    }
    .previous,
    .next {
      display: block;
      float: right;
      width: 30px;
      height: 30px;
      margin-top: -6px;
      overflow: hidden;
      cursor: pointer;
      text-align: right;

      &:before {
        //position:absolute;
        position: relative;
        width: 100%;
        content: url("../static/images/arrow-down.svg");
        display: inline-block;
        transform: rotate(90deg);
      }
    }

    .next:before {
      //position:absolute;
      position: relative;
      width: 100%;
      content: url("../static/images/arrow-down.svg");
      display: inline-block;
      transform: rotate(-90deg);
    }
  }

  & .cbox-image {
    float: left;
    text-align: left;
    padding: 0;
    //make the image box a little smaller because of text on the right side
    padding-right: $base-line;

    img {
      width: 100%;
      margin-bottom: 2 * $base-line;
    }

    @include breakpoint(small only) {
      padding-right: 0;
      img {
        margin-bottom: 0.5 * $base-line;
      }
    }
  }

  & .cbox-text {
    float: right;
    padding-left: 1.5rem;
    padding-right: 0;

    @include breakpoint(small only) {
      padding-left: 0;
      padding-top: 2 * $base-line;
    }

    // some different header logic in the design
    & h1 {
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
      font-weight: bold;
    }

    & h2 {
      @extend p;
      font-weight: 400;
      margin-bottom: 0;

      & + h2 {
        margin-bottom: 2 * $base-line;
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// Card (e.g. Mitarbeiter with or without image)
.card {
  @extend .rounded;
  background-color: $white;

  img {
    width: 100%;
  }

  a {
    display: block;
  }

  //use this to override cards, which have an a around the text.
  .card-label {
    display: block;
    @extend %sans-serif-regular;
    font-size: $fontsize-h4;
    color: $black;
    padding: rem-calc(6) 0.5 * $base-line;
    width: 100%;
    @extend .longword;
    span {
      margin-top: 0.5rem;
      display: block;
    }

    .card-label__header {
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// Card Grid (e.g. Mitarbeiter)

// Card Grid: using f6's block grid
.card-grid-wrapper,
.empty-card-grid-wrapper {
  padding: 0 $base-line * 0.5 $base-line;
}

.card-grid-wrapper--padding {
  padding: 0 $base-line * 1 $base-line;
}

////////////////////////////////////////////////////////////////////////////////
// Project detail

.detail__contact-image {
  max-width: 150px;
  height: 100%;
  margin-bottom: 0.5rem;
}

.detail-content-image {
  padding-top: 1.375 * $base-line;
}

.detail-content__team-header {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.detail-content__team-elements {
  display: flex;
  flex-wrap: wrap;
}

.detail-content__team-element {
  max-width: 150px;
  margin-right: 3rem;
  margin-bottom: 2rem;
}

.detail-content__person-image {
  margin-bottom: 0.5rem;
}

//if using the rich text editor in the project-detail we have to clean
//out all the styles set theere.
.detail-content article {
  .cbox:last-child {
    padding-bottom: 0;
  }
  .zebra {
    background-color: transparent;
  }

  .row,
  .cbox-row,
  .columns,
  :not(.richtext) > p {
    // only remove spacing in non-richtext p's
    margin: 0;
    padding: 0;
  }
}

@include breakpoint(small only) {
  // @extend inside @media is not allowed for
  // classes defined outside of @media

  .detail-content {
    //@extend .append-bottom;
    padding-bottom: $base-line;
  }

  .detail-data {
    //@extend .prepend-top;
    padding-top: $base-line;

    background-color: $zebra-dark;
  }
}

.detail-data {
  h4 {
    font-weight: 600;
  }
}

.detail-content__filter {
  margin: 0;
  li {
    list-style: none;
  }
}

.detail-content__subtitle {
  font-weight: 600;
  font-size: 0.9rem;
  color: $primary-color;
  line-height: normal;
  margin-bottom: 0.8rem;
  display: block;
}

.detail-content__category-link {
  color: $black;

  &:hover {
    color: $primary-color;
  }
}

.detail-content__title {
  &.detail-content__title--black a {
    color: $black;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Shariff

//REFACTOR!!!
//override default theme, lots of !importants though -> SORRY

//DIRTY, some strange issues so this is just ghfdskjlsjfkdl.

.util-sharing {
  width: 100%;
  height: 30px;
}

.shariff {
  display: inline-block;
  float: left;
  height: 30px;
  margin-bottom: 1rem;

  //fix but why?? foundation tables???
  &::before,
  ul::before {
    display: block;
  }

  ul {
    width: 114px;
    display: inline-block;

    li {
      margin-right: 6px !important;
      margin-bottom: 0 !important;
    }
  }
}

.shariff-fake-print {
  float: left;
  display: inline-block;
  height: 30px;

  .shariff-button {
    position: absolute;

    a {
      padding-left: 0.5 * $base-line;
    }
  }
}

.shariff-button {
  flex: none !important;
  min-width: 32px !important;
  width: 32px !important;
  height: 30px !important;

  .fa {
    line-height: 30px !important;

    //adjust icons
    &.fa-facebook {
      text-indent: 2px;
      line-height: 32px;
      font-size: 19px !important;
    }

    &.fa-linkedin {
      font-size: 19px !important;
    }
    &.fa-envelope {
      text-indent: -1px;
      font-size: 19px !important;
    }
  }

  a {
    width: 32px !important;
    height: 100% !important;

    display: flex !important;
    justify-content: center;
    align-items: center;

    .share_text {
      display: none !important;
    }

    //Fake a shariff button for print
    .shariff-fake-print & {
      background-color: #999;
      display: block;
      color: $white;
      height: 30px;
      //position: relative;
      text-decoration: none;

      .fa {
        width: 30px;
        line-height: 30px;
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// Blog

small,
p {
  a,
  strong,
  em,
  time,
  .fn,
  .vcard {
    display: inline-block;
  }
}

//in the blog the richtext is nested in a cbox, so omit the zebra and row paddings
.cbox.blog.zebra .cbox.zebra {
  background-color: transparent;
}

.blog .cbox-row .cbox-row {
  padding: 0;
  padding-bottom: $base-line;
}

.blog-entry-list {
  min-width: 300px;
  ul {
    list-style: none;
    margin: 0;
  }

  li {
    line-height: 1.2rem;
    margin-bottom: 1rem;

    a {
      color: #000000;
    }
    a:hover,
    a:focus {
      color: $primary-color;
    }
  }
}

//pagination falls out of the grid and base template is hidden in venv
//so just use f6 mixings to fix
.pagination-centered {
  @include grid-row;
}

////////////////////////////////////////////////////////////////////////////////
// Image captions
.img-caption {
  margin-top: 0.2rem;
  font-size: 0.9rem;
}

.news-filter {
  position: absolute;
  z-index: 2;

  .news-filter-backdrop {
    position: absolute;
    background: white;
    left: 0rem;
    top: -5rem;
    right: -100rem;
    bottom: 0rem;
  }

  .news-filter-content {
    position: relative;
    padding: 0 3rem 5rem 6rem;

    // save some space
    @media screen and (max-width: 1000px) {
      padding: 0 3rem 1.5rem 2rem;
    }

    .some-follow {
      padding-top: 1rem;

      p {
        font-size: 0.875rem;
      }
    }

    .some.button {
      display: inline-flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;

      padding-right: 1.5rem;
      padding-bottom: 0;
      padding-top: 0;

      background-color: #006699;
      border-radius: 3px;
      font-size: rem-calc(12px);

      height: rem-calc(34px);

      // language specific length
      [lang="fr"] & {
        min-width: $sidebar-button-length-fr;
      }

      [lang="en"] & {
        min-width: $sidebar-button-length-en;
      }

      [lang="de"] & {
        min-width: $sidebar-button-length-de;
      }

      &:hover {
        background-color: #004f76;
      }

      .button-label {
      }

      img {
        height: 32px;
      }
    }
  }
}

@include breakpoint(small only) {
  .news-filter__container {
    min-height: 320px;
    margin-bottom: 1rem;
  }
  .news-filter {
    width: 100%;
  }
  .news-filter-content {
    display: flex;
    justify-content: space-between;
  }
}

// News entries home

.news-element {
  margin-bottom: $base-line * 1.5;
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 95%;
    padding-top: $base-line * 1.5;
    border-bottom: 1px solid $light-gray;
  }
}

.news-element__inner-wrapper {
  display: flex;
  flex-direction: column;
  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.news-element__image-container {
  display: block;
  flex: 1 1 100%;
  height: 300px;
  margin-bottom: $base-line * 1.5;

  @include breakpoint(medium) {
    flex: 1 1 30%;
    max-width: 230px;
    height: 153px;
    margin-right: $base-line * 1.5;
    margin-bottom: 0;
  }
}

.news-element__image {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  min-height: 300px;

  @include breakpoint(medium) {
    min-height: auto;
  }
}

.news-element__tag {
  background: $primary-color;
  position: absolute;
  bottom: 0.8rem;
  height: auto;
  color: $white;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 0 1rem;
  font-size: 14px;
  margin-left: 0.35rem;
  width: auto;
  font-weight: bold;

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    right: 50%;
    margin-top: 10px;
    width: 0;
    height: 0;
    transform: rotate(-90deg) translateY(50%);
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 8px solid $primary-color;
  }
}

.news-element__content {
  flex: 1 1 70%;
  display: flex;
  flex-direction: column;
}

.news-element__header {
  display: block;
  color: $primary-color;
}

.news-element__title {
  max-width: 80%;
  margin-bottom: 0;
  font-size: 1.4rem;

  a {
    color: $black;
  }
}

.news-element__date {
  display: block;
  color: $medium-gray;
  margin-bottom: $base-line * 1.5;

  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

.news-element__link {
  display: block;
  text-transform: capitalize;
}

.news-element__lower-content {
  margin-top: 1rem;
}

// Team foldout

.project-browser__foldout-toggle {
  display: flex;
  margin-bottom: 3rem;
  align-items: center;

  h4 {
    color: $primary-color;
  }
}

.project-browser__foldout-content {
  position: relative;
  min-height: 30rem;
  &.is-collapsed {
    display: none;
  }

  .p-sticky {
    position: absolute;
    width: 100%;
  }
}

.toggle-icon {
  display: block;
  margin-left: 20px;
  overflow: hidden;
  cursor: pointer;
  text-align: right;
  //transform: rotate(-90deg);
  color: $primary-color;
  //transition: all 200ms ease;
  transform-origin: center;

  .is-toggled & {
    transform: rotate(180deg);
  }

  &:before {
    //position:absolute;
    position: relative;
    width: 100%;
    content: url("../static/images/arrow-down.svg");
    display: inline-block;
  }
}

.color-black {
  &,
  &:hover {
    color: $black;
  }
}

.cbox-spacer {
  width: 333px;
  max-width: 100%;
  height: auto;
}

.card-label {
  &.card-label--extended {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.card-label__header-title {
  padding-right: 0.5rem;
}

.section-service {
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .column {
    float: unset;
    .card,
    a {
      height: 100%;
    }
  }
}

//in services and topics, reduce the paddings
.section-service,
.section-topic {
  .cbox-row {
    padding-top: 0;
  }
}
