/*
* Typography
*/

$darker-header-color: #1d1c1c;

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fast.fonts.net/cssapi/90a0cfda-9e2d-4252-8362-60a57347caa2.css");

$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;
$font-family-sans-serif: "Open Sans", sans-serif; // 400, 700
$font-family-calibri: "Calibri W01";

%sans-serif-regular {
  font-family: $font-family-sans-serif;
  font-weight: 400;
  font-style: normal;
  color: $secondary-color;
}

%sans-serif-medium {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-medium;
  font-style: normal;
  color: $black;
}
%sans-serif-bold {
  font-family: $font-family-sans-serif;
  font-weight: 700;
  font-style: normal;
  color: $secondary-color;
}

%title-with-orange-line {
  border-left: 3px solid $primary-color;
  padding-left: 1rem;
  line-height: 2rem;
  margin-bottom: $base-line;
  color: $secondary-color;
}

$fontsize-h4: rem-calc(16 * 1.1);
$fontsize-date: rem-calc(12);

@font-face {
  font-family: "arrows";
  src: url("fonts/flexslider-icon.eot");
  src:
    url("fonts/flexslider-icon.eot?#iefix") format("embedded-opentype"),
    url("fonts/flexslider-icon.woff") format("woff"),
    url("fonts/flexslider-icon.ttf") format("truetype"),
    url("fonts/flexslider-icon.svg#flexslider-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}

html {
  &.nav-is-open {
    position: fixed;
    overflow: hidden !important;
  }
}

body {
  @extend %sans-serif-regular;
  color: $secondary-color;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
}

p {
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5 {
  @extend %sans-serif-regular;
  color: $darker-header-color;
}

h1,
h2,
h3 {
  @extend %title-with-orange-line;
}

h1 {
  margin-bottom: 2 * $base-line;

  @include breakpoint(small only) {
    margin-bottom: $base-line;
  }
}

/*
// debugging the headers
h1:before {
  content: "[h1]";
  display: inline-block;
}

h2:before {
  content: "[h2]";
  display: inline-block;
}

h3:before {
  content: "[h3]";
  display: inline-block;
}

h4:before {
  content: "[H4]";
  display: inline-block;
}
*/
