// rounded images global styles
.rounded {
  border-radius: $img-radius;
}

%top-cut {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

%bottom-cut {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

// lists

.inline-list {
  list-style: none;
  display: block;
  margin: 0;
  &.inline-list--category,
  &.inline-list--archive {
    li a.active {
      font-weight: bold;
      transition: all 200ms ease;
      position: relative;
      display: block;

      &:hover {
        transform: translateX(20px);
        span {
          opacity: 1;
          transform: translate(-25px, -1px);
        }
      }

      span {
        opacity: 0;
        position: absolute;
        font-size: 16px;
        display: inline-block;
        left: 0;
        top: 0;
        padding: 0 0.5rem;
      }
    }
  }
}

// spacings

.prepend-top {
  padding-top: $base-line;
}

.append-bottom {
  padding-bottom: $base-line;
}

// rounded images

img {
  @extend .rounded;

  &.cut {
    @extend %top-cut;
    @extend %bottom-cut;
  }

  &.top-cut {
    @extend %top-cut;
  }

  &.bottom-cut {
    @extend %bottom-cut;
  }
}

//for any shadow hover effect, look here before implemting:
//http://tobiasahlin.com/blog/how-to-animate-box-shadow/
.shadowed {
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease-in-out;

  &:hover {
    box-shadow: 0 1px 8px 4px rgba(0, 0, 0, 0.2);
    transform: translate3d(0, 1px, 0);
  }
}

.card__subtitle {
  font-weight: 600;
  font-size: 0.9rem;
  color: $primary-color;
  line-height: normal;
  margin-bottom: 0.35rem;
}

// navigation styles

.nav-item {
  text-transform: uppercase;

  a {
    color: $black;
  }

  &:hover a,
  &.active a {
    color: $primary-color;
  }
}

//Nav item
