/*

// Third-party libraries
@import 'foundation-sites/scss/vendor/normalize';

// Sass utilities
@import 'foundation-sites/scss/util/util';

// Global variables and styles
@import 'foundation-sites/scss/global';

// Overwrite with our custom settings
@import 'settings';

// Components
@import 'foundation-sites/scss/grid/grid';
@import 'foundation-sites/scss/typography/typography';
@import 'foundation-sites/scss/forms/forms';
@import 'foundation-sites/scss/components/visibility';
@import 'foundation-sites/scss/components/float';
@import 'foundation-sites/scss/components/button';
@import 'foundation-sites/scss/components/button-group';
// @import 'foundation-sites/scss/components/accordion-menu';
// @import 'foundation-sites/scss/components/accordion';
// @import 'foundation-sites/scss/components/badge';
// @import 'foundation-sites/scss/components/breadcrumbs';
@import 'foundation-sites/scss/components/callout';
@import 'foundation-sites/scss/components/close-button';
@import 'foundation-sites/scss/components/drilldown';
@import 'foundation-sites/scss/components/dropdown-menu';
@import 'foundation-sites/scss/components/dropdown';
@import 'foundation-sites/scss/components/flex-video';
@import 'foundation-sites/scss/components/label';
// @import 'foundation-sites/scss/components/media-object';
@import 'foundation-sites/scss/components/menu';
// @import 'foundation-sites/scss/components/off-canvas';
// @import 'foundation-sites/scss/components/orbit';
@import 'foundation-sites/scss/components/pagination';
// @import 'foundation-sites/scss/components/progress-bar';
@import 'foundation-sites/scss/components/reveal';
// @import 'foundation-sites/scss/components/slider';
@import 'foundation-sites/scss/components/sticky';
// @import 'foundation-sites/scss/components/switch';
@import 'foundation-sites/scss/components/table';
@import 'foundation-sites/scss/components/tabs';
@import 'foundation-sites/scss/components/title-bar';
@import 'foundation-sites/scss/components/top-bar';
// @import 'foundation-sites/scss/components/thumbnail';
// @import 'foundation-sites/scss/components/tooltip';
*/

// @import '~foundation-sites/scss/vendor/normalize';
@import "~foundation-sites/scss/util/util";
@import "~foundation-sites/scss/global";
@import "settings";
@import "~foundation-sites/scss/foundation";

// These styles are applied to <meta> tags, which are read by the Foundation JavaScript
.foundation-mq {
  font-family: "#{-zf-bp-serialize($breakpoints)}";
}

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
// @include foundation-media-object;
@include foundation-menu;
// @include foundation-off-canvas;
// @include foundation-orbit;
@include foundation-pagination;
// @include foundation-progress-bar;
@include foundation-reveal;
// @include foundation-slider;
@include foundation-sticky;
// @include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-title-bar;
@include foundation-top-bar;
// @include foundation-thumbnail;
// @include foundation-tooltip;
// @include top-bar-container;
