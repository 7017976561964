$stickyTopTrigger: 110px;

// RESULT LIST
.result-list {
  @extend .inline-list;

  //sticky box lies by default above result list which is then not clickable
  //move it to zero and result list to 1
  position: relative;
  z-index: 1;

  .card {
    cursor: pointer;
    margin-bottom: 1rem;
  }

  .card--project-archive {
    display: flex;

    .card-image {
      width: 300px;
      margin-right: 1rem;
      min-height: 180px;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;

      @include breakpoint(large) {
        width: 500px;
      }
    }
  }

  .card-label {
    padding-bottom: 0.6rem;
  }

  li {
    margin-bottom: $base-line;
  }

  h4 {
    @extend %sans-serif-regular;
    color: #000;
    margin-top: rem-calc(2);
    margin-bottom: rem-calc(2);
    font-size: $fontsize-h4;
  }

  .client,
  .date {
    color: #000;
    font-size: rem-calc(12);
  }

  .date {
    color: $secondary-color;
  }
}

.result-pagination {
  //sticky box lies by default above result list which is then not clickable
  //move it to zero and result list to 1
  position: relative;
  z-index: 1;
}

.loading {
  text-align: center;
  padding: 1rem;
}

// SPINNER
.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 0px auto;
}

.spinner-label {
  margin-top: $base-line;
  padding-left: $base-line;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $primary-color;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

// FILTER BOX
.p-sticky {
  @include breakpoint(medium) {
    position: fixed;
    //sticky box lies by default above result list which is then not clickable
    //move it to zero and result list to 1
    z-index: 0;

    //top has to be synced with projectBrowser.js
    top: $stickyTopTrigger;
    width: 100%;
    transition: all 0.2s ease;

    .columns {
      @include grid-column(4);
    }
  }
}

$services-color: $primary-color;

.text-search-box,
.filter-box {
  .select-label {
    @extend %sans-serif-bold;
    font-size: 1rem;
    color: $primary-color;

    & + .Select .Select-control {
      border-color: $primary-color;
    }
  }
}

.text-search-box {
  position: relative;
  margin-bottom: $base-line;

  .search-bar-submit {
    display: none;
  }

  .search-bar-field {
    position: relative;
    height: 36px;
  }

  input {
    position: relative;
    border-color: $primary-color;
    border-radius: 4px;
    height: 36px;
    margin: 0;

    &::-ms-clear {
      display: none;
    }
  }

  .search-bar-clear {
    @extend %sans-serif-regular;
    display: block;
    position: absolute;
    bottom: 6px;
    right: 10px;
    cursor: pointer;
    color: #999;

    &:hover {
      color: $primary-color;
    }
  }
}

.filter-box {
  .subtopic-label.disabled {
    color: $medium-gray;
    & + .Select .Select-control {
      border-color: $medium-gray;
    }
  }

  .service-label {
    color: $services-color;

    & + .Select .Select-control {
      border-color: $services-color;
    }
  }

  .Select {
    @extend .append-bottom;
  }
}

// LIST SORT

.sort-box {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 6px;

  @include breakpoint(small only) {
    position: relative;
    top: 0;
    right: -5px;
    text-align: right;
  }
}

.sort-select {
  //TODO make this somhow dynamic
  width: 180px;
  text-align: right;
  display: inline-block;

  .Select-control {
    border: none !important;
    background-color: transparent;
  }

  .Select-value {
    padding-right: 25px !important;
  }
}

// Pagination overrides to not all work from settings
.pagination {
  //overrride foundation here.
  @include breakpoint(small only) {
    li {
      display: inline-block;
    }
  }

  a {
    @extend %sans-serif-bold;
    outline: none;
    padding-left: 0.65rem;

    &:hover {
      color: $primary-color;
    }
  }

  .current a {
    color: $primary-color;
  }
}

// React CSS Transition Group

$trans-in: 40px;
$trans-out: 40px;

@include breakpoint(medium) {
  .list-transition-enter {
    opacity: 0;
    transform: translate($trans-in, 0);
    transform: translate3d($trans-in, 0, 0);
  }

  .list-transition-enter.list-transition-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transform: translate3d(0, 0, 0);
    transition-property: transform, opacity;
    transition-duration: 300ms;
    transition-timing-function: easeOut, linear;
    //transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1), linear;
  }

  .list-transition-leave {
    opacity: 1;
    transform: translate(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition-property: transform, opacity;
    transition-duration: 300ms;
    transition-timing-function: easeIn, linear;
    //transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1), linear;
  }

  .list-transition-leave.list-transition-leave-active {
    opacity: 0;
    transform: translate($trans-out, 0);
    transform: translate3d($trans-out, 0, 0);
  }
}

.project-browser__header {
  @include breakpoint(small only) {
    position: absolute;
    background-color: $light-gray;
    z-index: 1000;
  }
}

.project-browser__content {
  @include breakpoint(small only) {
    margin-top: 28rem;
  }
}

.project-browser__pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    margin: 0;
  }
}
