//foundation
@import "./foundation";

//flex slider
@import "flexslider/flexslider";

// featherlight lightbox
@import "~featherlight/release/featherlight.min.css";

// shariff
// @import '~shariff/build/shariff.complete.css';
// @import '~shariff/build/shariff.min.css';
@import "~shariff/dist/shariff.min.css";

//site-styles
@import "mixins.scss";
@import "_extensions.scss";

@import "typography.scss";
@import "header.scss";
@import "search.scss";
@import "components.scss";
@import "project-browser.scss";
@import "footer.scss";
@import "newsletter.scss";

@import "print.scss";

// @import "~react-select/dist/react-select.min.css";

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

.edit-tools {
  display: none;

  .button {
    margin-bottom: 0;
  }

  @include breakpoint(small) {
    bottom: 0;
    display: block;
    position: fixed;
    right: 0;
  }
}

:root {
  --f3cc-accept-background: #{$primary-color};
}
