//Searchbox specific classes

.searchbox {
  position: relative;
}

.searchbox-icon {
  @extend .nav-item;
  display: block;
  outline: none;

  &:hover {
    color: $primary-color;
  }

  .nav-is-open & {
    display: none;
  }
}

.search-filter {
  margin-top: 0.5rem;

  ul {
    display: inline-block;
    margin-left: 0;

    @include breakpoint(medium up) {
      margin-left: 1rem;
    }
  }

  li {
    display: inline-block;
    margin-right: 1rem;

    label {
      font-size: 1rem;
      color: $secondary-color;
    }

    input {
      width: auto;
    }
  }
}

.input-group-button {
  input {
    .nav-is-open & {
      background-color: $white;
      color: $primary-color;
      margin-left: 0.5rem;
    }
  }
}

//override F6
.top-bar input {
  width: 100%;
}

.searchbox__topics {
  .searchbox-input {
    position: relative;
  }
}

.searchbox-input {
  position: absolute;
  right: 0;
  width: 360px;
  margin-top: 0.5 * $base-line;
  display: none;
  z-index: 1000;

  &.searchbox-input--active {
    display: block !important;
  }

  //overrride F6
  .input-group {
    margin-bottom: 0;

    .nav-is-open & {
      max-width: 18rem;
    }
  }

  .nav-is-open & {
    margin-top: 1rem;
    display: block;
    position: relative;
  }

  input {
    margin-right: 0;
  }
}

//Result page

.search-results-search-field {
  .button--center {
    display: block;
    margin: 0 auto;
  }
}

.search-results {
  .cbox-row {
    padding: ($base-line * 2) 0;
  }
}

.search-result__helper {
  margin: 0.5rem 0;
}

.pdf-icon {
  display: block;
  background-image: url("../static/images/pdf.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
}

.search-result__title {
  color: $black;
  font-size: 1.4rem;
  &:hover {
    color: $black;
  }
}
