@media print {
  a[href]:after {
    content: none !important;
  }

  * {
    background-color: white !important;
  }

  .sticky-container {
    height: rem-calc(100) !important;
  }

  .sticky {
    position: relative !important;
    box-shadow: none !important;

    .logo .no-claim {
      display: none !important;
    }
    .logo .claim {
      display: block !important;
      width: 228px !important;
    }
  }

  header .top-bar-title,
  header .top-bar-right,
  .util-sharing,
  footer ul.menu {
    display: none !important;
  }

  .cbox:last-child {
    padding-bottom: 0 !important;
  }

  .cbox-row {
    padding: $base-line 0 !important;
  }

  footer {
    padding-top: 2 * $base-line !important;
  }
}
